import $ from 'jquery';

import AppleView from 'chairisher/view/apple';
import BaseController from 'chairisher/_controller';
import FacebookView from 'chairisher/view/facebook';

import executeRecaptcha from 'chairisher/view/helper/recaptcha';

import { getRecaptchaActionName } from 'chairisher/context/auth';

/**
 * Controller that binds actions for the login page
 */
class AuthController extends BaseController {
    bindPageActions() {
        super.bindPageActions();

        AppleView.loadAuthButton();
        FacebookView.bind(); // for facebook connect login button

        // Get recaptcha token on submit so timeout doesn't occur
        const $form = $('.js-form-auth');
        $form.on('submit', (e) => {
            e.preventDefault();
            $form.find('.btn-default').prop('disabled', true);

            executeRecaptcha(getRecaptchaActionName()).then(
                () => {
                    $form.get(0).submit(); // call submit directly on dom element to avoid infinitely calling event handler
                },
                () => {
                    $form.find('.btn-default').prop('disabled', false);
                },
            );
        });
    }
}

export default AuthController;
